import { Component, Inject, OnInit } from '@angular/core';
import { AppBaseComponent } from "@baseComponent";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { AuthService, PopupService } from "@services";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import { InteractionStatus } from "@azure/msal-browser";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [ './signin.component.scss' ]
})
export class SigninComponent extends AppBaseComponent implements OnInit {

  loginForm: FormGroup;
  rememberMe: string;
  oneTouch: boolean;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private router: Router,
              private alert: PopupService,
              private authServiceOLD: AuthService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService) {
    super();
    this.cargarVentanilla = this.cargarVentanilla.bind(this);
    this.rememberMe = localStorage.getItem('rememberMe') &&
    localStorage.getItem('rememberMe').length > 0 ? localStorage.getItem('rememberMe') : '';
    this.loginForm = this.fb.group(
      {
        email: [ this.rememberMe, [ Validators.required/*, this.isValidEmailFn*/ ] ],
        password: [ '', [ Validators.required, Validators.minLength(6), Validators.maxLength(50) ] ],
        remember: [ this.rememberMe !== '' ]
      }
    );
  }

  ngOnInit(): void {
    this.oneTouch = false;
    document.body.className = 'myScroll';
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    // ventanilla - tramite
    const metodoExterno = this.cargarVentanilla;
    window.addEventListener('message', function (event) {
      // Verifica el origen del mensaje
      if (event.origin == environment.VUDTS_URL_LOCAL || event.origin == environment.VUDTS_URL) {
        if (event.data && event.data.flujo) {
          const datos = event.data;
          localStorage.setItem('datosventanilla', JSON.stringify(datos));
          metodoExterno();
          const respuesta = datos.oid;
          // @ts-ignore
          event.source.postMessage(respuesta, event.origin);
        }
      }
    });
  }

  getErrorMessage = (field: string): string => {
    let message;
    switch (field) {
      case 'email':
        if (this.loginForm.get(field).hasError('required')) {
          message = `Es requerido`;
        } else if (this.loginForm.get(field).errors &&
          this.loginForm.get(field).errors['invalidEmail'] &&
          this.loginForm.get(field).errors['invalidEmail'].valid === false) {
          message = `No es válido`;
        }
        break;
      case 'password':
        if (this.loginForm.get(field).hasError('required')) {
          message = `Es requerido`;
        } else if (this.loginForm.get(field).hasError('minlength')) {
          message = `Permite al menos 8 caracteres`;
        } else if (this.loginForm.get(field).hasError('maxlength')) {
          message = `Permite hasta 50 caracteres`;
        }
        break;
    }
    return message;
  }

  login() {
    this.authServiceOLD.LoginB2CAzure();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  cargarVentanilla() {
    this.authServiceOLD.loginventanilla();
  }

}
