import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }


  /** Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  public getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserById/${ idCode }`);
  }

  /**
   * Obtiene codigo de ventanilla de un usuario dado su oid del B2C
   * @param oidToken_usuario Id dado por el b2c
   */
  public getCodeVentanillaByIdUser(oidToken_usuario: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetCodeVentanillaByIdUser/${ oidToken_usuario }`);
  }
  /**
   * Obtiene la info de la tabla user de la base de datos de security
   * @param oidToken_usuario Id dado por el b2c
   */
  public getUser(oidToken_usuario: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetUsers?type=codigo&search=${ oidToken_usuario }`);
  }

  /**
   * Obtiene lista de Tipos de Identificacion
   */
  public getIdentificationType(): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }
}
