import { DocsRequirement, StatusRequest } from "@enums";

export const PROCEDURE17 = {
  T_ID: 17,
  T_NAME: 'Reforma de estatutos de fundaciones, corporaciones y/o asociaciones de utilidad común y/o sin ánimo de lucro',
  T_DESCRIPTION: `Obtener la aprobación de las modificaciones realizadas a los estatutos establecidos
  inicialmente en las fundaciones, corporaciones y/o asociaciones de utilidad común y/o sin ánimo de
  lucro del subsector salud, domiciliadas en Bogotá D.C.`,
  REQ_FIRST_TIME: '2',
  MODIFICATION: '3',
  ATTENTION_TIME: 30,
  STATES_AVAILABLE_TO_EDIT_OR_SEE: [
    StatusRequest.REQUEST_CREATED,
    StatusRequest.REQUEST_INCOMPLETE,
    StatusRequest.REQUEST_APPROVED_SIGNED,
    StatusRequest.REQUEST_DESIST_APPROVED_SIGNED,
    StatusRequest.REQUEST_APPROVED_RESOURCE,
    StatusRequest.REQUEST_WITH_PUBLICATION,
    StatusRequest.REQUEST_ENDED_APPROVED_SIGNED,
    StatusRequest.REQUEST_ANULA
  ],
  RICH_TEXT_DF: `<p style="line-height:normal"><br></p>`
};

export const FATHER_IDS = {
  ORG_ORIG: 25,
  TYPE_MEET: 13,
};

export const RequirementDocsT17 = [
  {
    id: DocsRequirement.CartaSolicitud,
    descripcion: 'Solicitud',
    helpText: 'La Solicitud debe estar suscrita por el representante legal.',
    selected: false,
    required: true,
    keyFileName: 'CartaSolicitud'
  },
  {
    id: DocsRequirement.ActaConstitucion,
    descripcion: 'Acta',
    helpText: 'Copia del Acta, según estatutos, en las que conste la aprobación de las respectivas reformas,' +
      ' con firmas del presidente y del secretario de la respectiva reunión.',
    selected: false,
    required: true,
    keyFileName: 'ActaConstitucion'
  },
  {
    id: DocsRequirement.EstatutosVigentes,
    descripcion: 'Estatutos vigentes',
    helpText: '',
    selected: false,
    required: true,
    keyFileName: 'EstatutosVigentes'
  },
  {
    id: DocsRequirement.EstatutosEntidad,
    descripcion: 'Estatutos propuestos',
    helpText: 'Copia de los Estatutos que incluyen todas las modificaciones introducidas, con firmas del presidente' +
      ' y del secretario de la reunión en que se aprobaron las reformas  y fecha de su aprobación.',
    selected: false,
    required: true,
    keyFileName: 'EstatutosEntidad'
  },
  {
    id: DocsRequirement.Convocatoria,
    descripcion: 'Convocatoria',
    helpText: 'Copia del medio de convocatoria del máximo órgano social según los estatutos.',
    selected: false,
    required: true,
    keyFileName: 'Convocatoria'
  },
  {
    id: DocsRequirement.Certificacion,
    descripcion: 'Certificación',
    helpText: 'La Certificación actualizada expedida por el representante legal, o por el presidente de la respectiva reunión de elección,' +
      ' en la que certifique el número total de miembros que integran la ESAL y el número de afiliados asistentes a la reunión.',
    selected: false,
    required: true,
    keyFileName: 'Certificación'
  },
  {
    id: DocsRequirement.CuadroComparativa,
    descripcion: 'Cuadro Comparativo',
    helpText: 'Cuadro Comparativo entre los estatutos de la ESAL actual y los estatutos que se desean reformar.',
    selected: false,
    required: true,
    keyFileName: 'CuadroComparativa'
  }
];

export const Specialties = {
  JURIDIC: '868B87ED-07D1-4FF9-B8A1-34AAE4529E71'
}

//tmp
export function CUSTOM_NAME(name: string): string {
  return name.slice(0, 20).trim();
}

export const QuillEditorStyle = {
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  fontSize: '14px'
};

export const QuillEditorDisabledStyle = {
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  fontSize: '14px',
  backgroundColor: '#e9ecef'
};

export const MonthNames = [ '', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
