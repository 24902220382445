import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FlowActivity } from "@models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FlowActivityService {

  constructor(private http: HttpClient) {
  }

  /**
   * Obtiene los estados habilitados para el proceso actual
   * @param roleId
   * @param currentStateId
   */
  getValidationResultOptions(roleId: any, currentStateId: number): Observable<FlowActivity[]> {
    return this.http.get<FlowActivity[]>(`${ environment.T16_VALIDATION_API_URL }/Request/GetFlowActivity/${ roleId }/${ currentStateId }`);
  }
}
