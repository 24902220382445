import { AbstractControl, FormGroup } from '@angular/forms';


export class AppBaseComponent {
  clearField = (form: FormGroup, field: string): void => {
    form.get(field).reset()
  }

  isDevelopment = (): boolean => {
    return window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
  }

  openBlankPage = (url: string): void => {
    window.open(url, '_blank');
  }

  isTouchedField = (form: FormGroup, field: string): boolean => {
    return form?.get(field).touched === true && form?.get(field).invalid;
  }

  isNotPristinedField = (form: FormGroup, field: string): boolean => {
    return form?.get(field).touched === true
  }

  isValidField = (form: FormGroup, field: string): boolean => {
    return (
      form?.get(field).touched && form?.get(field).valid
    );
  }

  isValidEmailFn = (control: AbstractControl): { [key: string]: any } | null => {
    //return /^[\w]+([\._-]?\w+)*[\w]+@{1}[\w]+\.[a-z]{2,3}$/.test(control.value) ? null :
    return /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(control.value) ? null :
      {
        invalidEmail: {
          valid: false,
          value: control.value,
          message: 'Formato no válido'
        }
      };
  }

  isValidWebsiteFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (/^([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/gi.test(control.value) === true || control.value === null)
      return null
    else
      return {
        invalidWebsite: {
          valid: false,
          value: control.value,
          message: 'No es válido, utilice el formato www.sitio.com'
        }
      };
  }

  isValidHttpWebsiteFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (/^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/gi.test(control.value) === true || control.value === null || control.value === '')
      return null
    else
      return {
        invalidHttpWebsite: {
          valid: false,
          value: control.value,
          message: 'No es válido, utilice el formato http://www.sitio.com'
        }
      };
  }

  isValidPhoneFn = (control: AbstractControl): { [key: string]: any } | null => {
    return /^[1-9]\d{20}$/.test(control.value) ? null :
      { invalidPhone: { valid: false, value: control.value, message: 'No es válido' } };
  }

  isDateValid = (control: AbstractControl) => {
    const date: Date | string = new Date().toString();
    // @ts-ignore
    return (date instanceof Date) ? null : { isNotDateValid: true };
  }

  keyPressNumbers = (event): boolean => {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  keyPressLetters = (event): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-ZÑÁÉÍÓÚÜáéíóúüñ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphanumeric = (event): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-ZÑÁÉÍÓÚÜáéíóúüñ0-9 #$@!%&*?¡"+,.:;='^|~_()¿{}-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressTelephone = (event): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressPhone = (event): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-_]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressLettersAndNumbers = (event): boolean => {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[A-Za-z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  formatDateToSave = (customDate: string): string => {
    const expDate = customDate.split('/');
    return `${ expDate[2] }-${ expDate[1] }-${ expDate[0] }`;
  }

  formatDateToLoad = (customDate: string): string => {
    try {
      const firstExpression = customDate.split('T')[0];
      const secondExpression = firstExpression.split('-');
      return `${ secondExpression[2] }/${ secondExpression[1] }/${ secondExpression[0] }`;
    } catch (e) {
      return customDate;
    }
  }

  removeEndLine = (text) => (text.replace(/[^\n]/, '')).trim();

  b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
