import { getColombiaHolidaysByYear } from 'colombia-holidays';
import moment from "moment-timezone";
import { PROCEDURE17 } from "@consts";

/**
 * Realiza la conversión de un base64 a blob
 * @param b64Data
 * @param contentType
 * @param sliceSize
 */
export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function letGoTop(x: number = 0, y: number = 0) {
  let mainInter = document.getElementById('interno');
  mainInter?.scroll(x, y);
  let main = document.getElementById('content-layout-id');
  main?.scroll(x, y);
}


export function truncateText(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

/**
 * Remover la extensión de un string (nombre de un documento)
 * @param filename
 */
export function removeExtension(filename) {
  return (
    filename.substring(0, filename.lastIndexOf('.')) || filename
  );
}


/**
 * Obtiene los dias festivos del(los) anios indicados
 * @param years array de anios
 */
export function getHolidaysByYears(years: any[]) {
  const holidays = [];
  for (const _year of years) {
    const holidaysInYear = getColombiaHolidaysByYear(_year);
    for (const holidayElement of holidaysInYear) {
      holidays.push(holidayElement.holiday);
    }
  }
  return holidays;
}

/**
 * Agrega dias laborales a una fecha
 * @param dateToCheck fecha indicada
 * @param daysToAdd dias laborales a sumar
 * @param holidays array de fechas festivas (no requerido) [2023-09-09,2023-09-10]
 */
export function addWorkingDaysToDate(dateToCheck: Date, daysToAdd: number, holidays = []) {
  const result = {
    daysAdded: daysToAdd,
    nonWorkingDays: 0,
    workingDays: 1,
    businessDaysRemainingUntilToday: daysToAdd,
    businessDaysDiff: 0,
    startDate: moment(dateToCheck).toDate(),
    lastDate: null
  };
  const CAttentionTime = PROCEDURE17.ATTENTION_TIME;
  const currentDate = moment().tz('America/Bogota').format('YYYY-MM-DD');
  const dateToVerify = moment(dateToCheck)
  while (result.workingDays < CAttentionTime) {
    let formatDate = moment(dateToVerify).format('YYYY-MM-DD')
    if (formatDate < currentDate) {
      result.businessDaysRemainingUntilToday--;
    }

    if (dateToVerify.weekday() === 6) {
      dateToVerify.add(2, 'days');
      result.nonWorkingDays = result.nonWorkingDays + 2;
    } else if (dateToVerify.weekday() === 0 || holidays.indexOf(formatDate) != -1) {
      dateToVerify.add(1, 'days');
      result.nonWorkingDays++;
    } else {
      dateToVerify.add(1, 'days');
      result.workingDays++;
    }
  }
  result.businessDaysDiff = daysToAdd - result.businessDaysRemainingUntilToday;
  result.lastDate = dateToVerify.toDate();
  return result;
}

export function removeWorkingDays(dateToCheck: Date, daysToRemove: number, holidays = []) {
  const result = {
    daysToRemove,
    nonWorkingDays: 0,
    workingDays: 1,
    startDate: null,
    lastDate: moment(dateToCheck).toDate()
  };
  const CAttentionTime = PROCEDURE17.ATTENTION_TIME;
  const dateToVerify = moment(dateToCheck)
  while (result.workingDays < CAttentionTime) {
    let formatDate = moment(dateToVerify).format('YYYY-MM-DD')
    if (dateToVerify.weekday() === 6) {
      dateToVerify.subtract(1, 'days');
      result.nonWorkingDays = result.nonWorkingDays + 1;
    } else if (dateToVerify.weekday() === 0) {
      dateToVerify.subtract(2, 'days');
      result.nonWorkingDays = result.nonWorkingDays + 2;
    } else if (holidays.indexOf(formatDate) != -1) {
      dateToVerify.subtract(1, 'days');
      result.nonWorkingDays = result.nonWorkingDays + 1;
    } else {
      dateToVerify.subtract(1, 'days');
      result.workingDays++;
    }
  }
  result.startDate = dateToVerify.toDate();
  return result;
}

