import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  entitySource = new BehaviorSubject(null);
  entityTarget$ = this.entitySource.asObservable();

  constructor(private http: HttpClient) {
  }

  checkEntity(json: { entidad: string, nit: string }): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Request/ValidateToInserT17/${ json.entidad }/${ json.nit }`);
  }
}
