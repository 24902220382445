export enum DocsRequirement {
  // DOCUMENTOS REGISTRO
  CartaSolicitud = 26,
  ActaConstitucion = 27,
  EstatutosEntidad = 28,
  Convocatoria = 30,
  Certificacion = 31,
  CuadroComparativa = 32,
  EstatutosVigentes = 38,

  // DOCUMENTOS FLUJO PROCESO
  DocumentoAsocRevision = 33,
  ActaVisitaRevision = 21,
  ComprobantePublicacion = 34,
  ComprobantePagoGaceta = 35,
  RecursoReposicion = 36,
  CertificadoExistencia = 37,
}
