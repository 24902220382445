export enum Rol {
  CitizenOrApplicant = 'solicitante',
  Validator = 'validador',
  Coordinator = 'coordinador',
  Specialist = 'especialista',
  VicePrincipal = 'subdirector'
}

export enum RoleId {
  solicitante = 1,
  validador = 9,
  coordinador = 10,
  especialista = 11,
  subdirector = 12,
  visita = 6
}

export enum RoleGuId {
  solicitante = '58eda51f-7e19-47c4-947f-f359bd1fc732',
  validador = 'efc7ab5b-5b58-4862-9bb1-4b1e0d7f0243',
  coordinador = 'dad11c31-3709-42cb-bcce-4b52ae741935',
  especialista = 'b8042b94-0f7a-4585-aa8a-9b9592746219',
  subdirector = '3ea40da8-99b0-46a9-9f76-32fb5599a8e5',
  visita = '8a3639ed-84c7-4dd0-870c-9c22236c4d53'
}
