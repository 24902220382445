import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampusService {

  campusSource = new BehaviorSubject(null);
  campusTarget$ = this.campusSource.asObservable();

  campusOne = new BehaviorSubject(null);
  campusOne$ = this.campusOne.asObservable();

  totalCampus = new BehaviorSubject(null);
  totalCampus$ = this.totalCampus.asObservable();

  constructor(private http: HttpClient) {
  }

}
