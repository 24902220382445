import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesApp } from "@enums";
import { SigninComponent } from "./core/components/signin/signin.component";
import { MsalLocalGuard } from "./core/guards/msal-local.guard";
import { IsLoggedGuard } from "./core/guards/is-logged.guard";
import { NotFoundComponent } from "./core/components/not-found/not-found.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [ MsalLocalGuard ],
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule)
  }, {
    path: RoutesApp.SignIn, component: SigninComponent,
    canActivate: [ IsLoggedGuard ]
  }, { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
