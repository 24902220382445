export enum StatusRequest {
  REQUEST_CREATED = 1,
  REQUEST_SENDED = 2,
  REQUEST_CANCELED = 3,
  REQUEST_CANCELED_SYSTEM = 4,
  REQUEST_ANULA = 5,
  REQUEST_SDS_APPROVE = 6,
  REQUEST_WITH_VISIT_ACT = 7,
  REQUEST_APPROVED_SIGNED = 8,
  REQUEST_INCOMPLETE = 9,
  REQUEST_CORRECTED = 10,
  REQUEST_REPLENISHNMENT_RESOURCE = 11,
  REQUEST_WITH_RESOURCE_APPROVED = 12,
  REQUEST_APPROVED_RESOURCE = 13,
  REQUEST_SPECIALIST_REVISION = 14,
  REQUEST_PAY_GAZZETA_CIU = 15,
  REQUEST_WITH_PUBLICATION = 16,
  REQUEST_ENDED_APPROVED_SIGNED = 17,
  REQUEST_DESISTI_SYTEM = 18,
  DESISTI_SDS_APPROVED = 19,
  REQUEST_DESIST_APPROVED_SIGNED = 20,
  REQUEST_APPROVED_BY_COORDINATOR = 21,
  RESOURCE_APPROVED_BY_COORDINATOR = 22,
  DESISTI_APPROVED_BY_COORDINATOR = 23,
  REQUEST_EXISTENCE_CERTIFICATE_VALIDATOR = 25,
  REQUEST_EXISTENCE_CERTIFICATE_COORDINATOR = 26,
  REQUEST_RETURNED_TO_COORDINATOR = 27,
}
