import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Specialties } from "@consts";

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {

  constructor(private http: HttpClient) {
  }

  getSpecialties(): Observable<any> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Especialidades`)
      .pipe(
        map(data => data.filter(specia => specia?.especialidad_ID == Specialties.JURIDIC.toLowerCase()))
      );
  }

  getSpecialistsBySpecialtyId(specialtyId: string): Observable<any> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Especialidades/${ specialtyId.toLowerCase() }/Especialistas`);
  }

  ObtenerEspecialidadPorIdSolicitudYEspecialidad(IdRequest, Specialty): Observable<any> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Especialidades/${ IdRequest }/${ Specialty }`);
  }
}
