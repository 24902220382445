import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { PROCEDURE17 } from "@consts";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  /**
   * Obtiene los usuarios por rol y tramite
   * @param roleGuId ID del rol en tramites
   */
  public getUsersByRole(roleGuId: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetUserbyIdrol/${ roleGuId }/${ PROCEDURE17.T_ID }`);
  }

  /**
   * Obtiene el rol de un usuario dado su oid del b2c
   * @param oid Id dado por el b2c del usuario a buscar
   */
  getRoleByIdUser(oid: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetRoleByIdUser/${ oid }`);
  }

  /**
   * Obtiene codigo de ventanilla de un usuario dado su oid del B2C
   * @param oidToken_usuario Id dado por el b2c
   */
  public getCodeVentanillaByIdUser(oidToken_usuario: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SECURITY_URL }/v2/Security/GetCodeVentanillaByIdUser/${ oidToken_usuario }`);
  }
}
