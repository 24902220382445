import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthModel, SSOAuthResponse } from '@models';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Rol, RoutesApp } from "@enums";
import { UserService } from "./user.service";
import { PopupService } from "./popup.service";
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthService {
  urlInter = 'https://login-appservice-back2.azurewebsites.net/auth/';
  public currentUserSubject: BehaviorSubject<SSOAuthResponse>;
  public currentUser: Observable<SSOAuthResponse>;
  private users!: AuthModel[];
  private userByDefault!: SSOAuthResponse;
  counterChangesRefresh = 1;
  response = false;

  subSolicitante = new BehaviorSubject(null);
  subSolicitante$ = this.subSolicitante.asObservable();

  private noInterceptorHttpClient: HttpClient;

  constructor(private http: HttpClient,
              handler: HttpBackend,
              private router: Router,
              private msalService: MsalService,
              private popupService: PopupService,
              private userService: UserService) {
    this.users = [];

    this.currentUserSubject = new BehaviorSubject<SSOAuthResponse>(JSON.parse(String(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

    this.noInterceptorHttpClient = new HttpClient(handler);
  }

  public get currentUserValue(): SSOAuthResponse {
    return this.currentUserSubject.value;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (err) {
      console.error(err)
      return null;
    }
  }

  isCurrentUserAndNotTokenExpired(): boolean {
    try {
      const currentUser = this.currentUserValue;
      if (localStorage.getItem('dateIn') === undefined || localStorage.getItem('dateIn') === null) {
        localStorage.setItem('dateIn', new Date().toString());
      }
      const before = new Date(localStorage.getItem('dateIn'));
      let dataRefresh = 0;
      if (!isNaN(currentUser.refresh_expires_in)) {
        dataRefresh = currentUser.refresh_expires_in;
      }
      let refresh = (0.9 * dataRefresh) / 60;
      const timeReal = new Date();
      const diff = Math.round(((timeReal.getTime() - before.getTime()) / 60000) / this.counterChangesRefresh);

      let valueExpire = 0;
      if (localStorage.getItem('currentUser')) {
        valueExpire = JSON.parse(localStorage.getItem('currentUser'))?.expires_in ? JSON.parse(localStorage.getItem('currentUser')).expires_in : 0;
      }
      const compareRefresh = 0.9 * (valueExpire / 60);
      if (refresh <= diff) {
        this.cleanAll();
        return false;
      } else if (compareRefresh <= diff) {
        return this.response;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  public async LoginB2CAzure(): Promise<any> {
    this.msalService.loginPopup().subscribe(
      async res => {
        let codeventanilla = 0;
        await this.userService.getCodeVentanillaByIdUser(res.account.idTokenClaims.oid).subscribe({
          next: (ventanillaCode) => {
            if (ventanillaCode.data == -1) {
              const vtUrl = environment.VUDTS_URL;
              this.popupService.errorAlert(`Hemos detectado que no se ha completado el registro, porfavor dirigase al portal de <a href='${vtUrl}' target="_blank" rel="noopener">Agilínea</a>`, 4500);
              setTimeout(() => this.cleanAllAndLogout(), 4500);
              return;
            } else {
              codeventanilla = ventanillaCode.data;
            }


          }, error: (err) => {
            this.popupService.errorAlert('Usuario no registrado por el flujo B2C', 4000);
            setTimeout(() => this.cleanAllAndLogout(), 4000);
          }
        });

        this.userService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role => {
          if (role.data != null) {
            const currentUser = {
              access_token: res.idToken,
              sub: res.account.idTokenClaims.sub,
              refresh_token: res.account.idTokenClaims.auth_time + "",
              refresh_expires_in: res.account.idTokenClaims.exp,
              notBeforePolicy: res.account.idTokenClaims.iat ? res.account.idTokenClaims.iat : 0,
              scope: res.scopes[0] ? res.scopes[0] : '',
              id_token: res.idToken ? res.idToken : '',
              token_type: res.tokenType,
              session_state: res.state ? res.state : '',
              expires_in: res.account.idTokenClaims.exp,
              rol: this.getExactlyRole(role.data[0].value.toLowerCase()),
              sid: '',
              email_verified: role.data[0].email ? role.data[0].email : false,
              name: res.account.idTokenClaims.name,
              preferred_username: res.account.username,
              given_name: res.account.idTokenClaims.given_name + "",
              family_name: res.account.idTokenClaims.family_name + "",
              email: role.data[0].email,
              userId: res.account.idTokenClaims.oid,
              codeventanilla: codeventanilla
            };
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.currentUserSubject.next(currentUser);
            this.popupService.infoAlert('Bienvenid@ a Secretaría de Salud Bogotá.', 4000);
            if (currentUser.rol == Rol.CitizenOrApplicant) {
              this.router.navigate([`${RoutesApp.AddRequestProcedure17}`]);
            } else {
              this.router.navigate([`${RoutesApp.Dashboard}`]);
            }
          } else {
            this.popupService.errorAlert('Usuario no registrado por el flujo', 4000);
            setTimeout(() => this.cleanAllAndLogout(), 4000);
          }

        });
      }
    )
  }

  public async loginventanilla() {
    const ventanilla = JSON.parse(localStorage.getItem('datosventanilla'));
    if (ventanilla) {

      let codeventanilla = 0;
      await this.userService.getCodeVentanillaByIdUser(ventanilla.oid).subscribe({
        next: (ventanillaCode) => {

          codeventanilla = ventanillaCode.data;
        }
      });

      this.userService.getRoleByIdUser(ventanilla.oid).subscribe(role => {
        if (role.data != null) {
          const currentUser = {
            access_token: ventanilla.flujo.idToken,
            sub: ventanilla.flujo.account.idTokenClaims.sub,
            refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time + "",
            refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ? ventanilla.flujo.account.idTokenClaims.iat : 0,
            scope: ventanilla.flujo.scopes[0] ? ventanilla.flujo.scopes[0] : '',
            id_token: ventanilla.flujo.idToken ? ventanilla.flujo.idToken : '',
            token_type: ventanilla.flujo.tokenType,
            session_state: ventanilla.flujo.state ? ventanilla.flujo.state : '',
            expires_in: ventanilla.flujo.account.idTokenClaims.exp,
            rol: this.getExactlyRole(role.data[0].value.toLowerCase()),
            sid: '',
            email_verified: role.data[0].email ? role.data[0].email : false,
            name: ventanilla.flujo.account.idTokenClaims.name,
            preferred_username: ventanilla.flujo.account.username,
            given_name: ventanilla.flujo.account.idTokenClaims.given_name + "",
            family_name: ventanilla.flujo.account.idTokenClaims.family_name + "",
            email: role.data[0].email,
            userId: ventanilla.flujo.account.idTokenClaims.sub,
            codeventanilla: codeventanilla
          };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.currentUserSubject.next(currentUser);
          if (currentUser.rol == Rol.CitizenOrApplicant) {
            this.router.navigate([`${RoutesApp.AddRequestProcedure17}`]);
          } else {
            this.router.navigate([`${RoutesApp.Dashboard}`]);
          }
        } else {
          this.popupService.errorAlert('Usuario no registrado por el flujo', 4000);
        }
      });
    }
  }

  private getExactlyRole(role): string {
    //roles en ventanilla contra los nuestros
    if (role == 'ciudadano') {
      return 'solicitante';
    }
    return role == 'funcionario' ? 'validador' : role;
  }

  cleanAll() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl(RoutesApp.SignIn);
  }

  cleanAllAndLogout() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      }).subscribe(resp => {
        localStorage.clear();
        this.currentUserSubject.next(null);
      });
    } else {
      localStorage.clear();
      this.currentUserSubject.next(null);
      this.router.navigateByUrl(RoutesApp.SignIn);
    }
  }
}
